$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

.slickDefault {
    margin: 0 auto;
    width: 100%;
    height: 300px;

    .slick-list {
        height: inherit;
        .slick-track{
            height: inherit;
            .slick-slide {
                height: inherit;
                > div {
                    height: inherit;
                    .item {
                        height: inherit;

                        background-size: cover;
                        background-position: center;
                        > * {
                            height: inherit;
                        }
                    }
                }
            }
        }
    }
}

.slickHero {
    background: #000000;
    height: 480px;

    .slick-slide {
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 100%;
            //width: 100vw;
            //height: 100vh;
            object-fit: cover;
            //filter: blur(5px);
        }

        .info {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;

            color: #ffffff;

            .info-inner {
                max-width: 600px;
            }

            h3 {
                font-size: 30px;
                text-transform: uppercase;
                animation: fadeOutRight 1s both;
            }

            p {
                font-size: 16px;
                overflow: hidden;
                height: 60px;
                animation: fadeOutLeft 1s both;
            }
        }

        &.slick-active {
            //animation: zoomIn 1s both;

            .info {
                h3 {
                    animation: fadeInDown 1s both 1s;
                }
                p {
                    animation: fadeInLeft 1s both 1.5s;
                }
            }
        }
    }

    .prevArrow,
    .nextArrow {
        position: absolute;
        top: 50%;
        width: 45px;
        height: 45px;
        background: rgba(0, 0, 0, 0.5);
        margin-top: -22.5px;
        text-align: center;
        font-size: 20px;
        line-height: 45px;
        color: #ffffff;
        z-index: 5;
        cursor: pointer;
    }
    .prevArrow {
        left: 0;
    }
    .nextArrow {
        right: 0;
    }

    .slick-dots {
        position: absolute;
        height: 6px;
        background: rgba(255,255,255,.20);
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin: 0;
            float: left;
            width: 0;
            height: 6px;
            background: #d62828;
            position: absolute;
            left: 0;
            bottom: 0;

            &.slick-active {
                width: 100%;
                animation: ProgressDots 11s both;
            }

            button {
                display: none;
            }
        }
    }
}
.slickFlexible {
    height: 300px;

    .slick-slide {
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 100%;

            object-fit: cover;
            border-radius: 4px;
        }
    }
    .slick-slide {
        .item {
            border-radius: 4px;
        }
    }

    .slick-dots {
        bottom: 8px;

        li button:before {
            font-size: 12px;
            color: #ffffff;
        }
    }

    .slick-prev {
        left: 5px;
        z-index: 1;

        &::before {
            color: transparent;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
        }
    }
    .slick-next {
        right: 5px;
        &::before {
            color: transparent;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
        }
    }
}

@-webkit-keyframes ProgressDots {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes ProgressDots{
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
