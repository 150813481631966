@import '~leaflet/dist/leaflet.css';

$leaflet-path: '~leaflet/dist/';

/*.lvml {
    behavior: url(#default#VML);
}*/
.leaflet-control-layers-toggle {
    background-image: url($leaflet-path + 'images/layers.png');
}
.leaflet-retina .leaflet-control-layers-toggle {
    background-image: url($leaflet-path + 'images/layers-2x.png');
}

.leaflet-default-icon-path {
    background-image: url($leaflet-path + 'images/marker-icon.png');
}
