[data-fancybox] {
    outline: none;
}

.fancybox-container {
    .fancybox-bg {
        background-color: #333333;
        opacity: 1;
    }
    .fancybox-caption {
        &:before {
            background-image: none;
        }
        &:after {
            border: 0;
        }
    }
    .fancybox-toolbar .fancybox-button {
        outline: none;
    }
}
.fancybox-share__button {
    .fab {
        font-size: 19px;
    }
}
.fancybox-share__button--google {
    background-color: #d34836;

    &:hover {
        background-color: #c74836;
    }
}

.fancybox-share__button--tumblr {
    background-color: #36465D;

    &:hover {
        background-color: #24364c;
    }
}
.fancybox-share__button--telegram {
    background-color: #0088CC;

    &:hover {
        background-color: #0074b4;
    }
}
.fancybox-share__button--threema {
    background-color: #333333;

    &:hover {
        background-color: #000000;
    }
}
.fancybox-share__button--whatsapp {
    background-color: #25D366;

    &:hover {
        background-color: #24ba5d;
    }
}

.fancybox-art-gallery {
    .fancybox-bg {
        background: #703C14;
        //background-image: url(../../public/images/hintergruende/f_elefantenhaut-02.jpg);
    }
    .fancybox-caption {
        background: #D5BE6E;
        //background-image: url(../../public/images/hintergruende/f_elefantenhaut-01.jpg);
        color: #703C14;
        //border-top: 3px white ridge;

        h3 {
            border-bottom: 2px #703C14 solid;
        }

        p {
            text-align: left;
            color: #005e00;
        }

        font-size: 15px;

        bottom: 0;
        left: 0;
        padding: 20px;
        right: 0;
        top: auto;
        height: 50%;
        overflow-x: hidden;
    }

    /* Adjust content area position */
    .fancybox-stage {
        bottom: 50%;
    }

    .fancybox-button--arrow_left {
        top: 25%;
    }

    .fancybox-button--arrow_right {
        top: 25%;
    }

    @include media-breakpoint-up(md) {
        /* Move caption area to the right side */
        .fancybox-caption {
            border-top: none;
            //border-left: 3px white ridge;

            bottom: 0;
            left: auto;
            right: 44px;
            top: 0;
            width: 356px;
            height: 100%;
        }

        /* Adjust content area position */
        .fancybox-stage {
            right: 400px;
            bottom: 0;
        }

        /* Align buttons at the right side */
        .fancybox-toolbar {
            background: #3b3b45;
            bottom: 0;
            left: auto;
            right: 0;
            top: 0;
            width: 44px;
        }

        /* Remove background from all buttons */
        .fancybox-button {
            background: transparent;
        }

        .fancybox-button--arrow_left {
            top: 50%;
        }
        .fancybox-button--arrow_right {
            top: 50%;
            right: 408px;
        }
    }
}
