@import url('https://fonts.googleapis.com/css?family=Kaushan+Script|Montserrat:400,700|Roboto+Slab:100,300,400,700|Droid+Serif:400,700,400italic,700italic&display=swap');

$custom-dark1: #222222;
$backgroundColor: #fafafb;

@mixin serif-font {
    font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin heading-font {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
}

/* Restyle Bootstrap */
.btn-primary {
    @include button-variant(map-get($theme-colors, 'primary'), map-get($theme-colors, 'primary'));
    @include heading-font;

    color: $custom-dark1;
    font-weight: 700;

    &:focus, &.focus {
        color: $custom-dark1;
    }
}

.btn-xs {
    $btn-padding-y-xs: 0.1rem;
    $btn-padding-x-xs: 0.3rem;
    $btn-font-size-xs: 0.8rem;
    $btn-border-radius-xs: 0.15rem;
    @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

html {
    height: 100%;
}
.page {
    min-height: calc(100vh - 74px - 56px);
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    padding-top: 74px;
    /*padding-bottom: 56px;*/
    min-height: 100%;
    font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: $backgroundColor;
    background-image: url('../../public/images/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.page {
    margin: 0 auto;
    padding-bottom: 30px;
}

.slider {
    border: 1px solid red;
    height: 480px;
}

.cybproject-slick_root {
    .slick-slide {
        height: 480px;
    }
}

a {
    outline: none;
    color: map-get($theme-colors, 'primary');

    &:hover, &:focus,
    &:active, &.active {
        outline: none;
        color: darken(map-get($theme-colors, 'primary'), 10%);
    }
}

.btn:focus,
.btn:active, .btn.active,
.btn:active:focus {
    outline: none;
}

h1, h2, h3, h4, h5, h6 {
    @include heading-font;
    font-weight: 700;
}

p {
    font-size: 14px;
    line-height: 1.75;
}

/* Bootstrap dots */
.carousel-indicators.dots li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

/* Navigation */
nav.navbar {
    background-color: #222222;

    &.navbar-dark {
        .navbar-toggler {
            color: map-get($theme-colors, 'primary');
            outline: none;

            .navbar-toggler-icon {
                $primaryColorRgba: 'rgba(' + red(map-get($theme-colors, 'primary')) + ', ' + green(map-get($theme-colors, 'primary')) + ', ' + blue(map-get($theme-colors, 'primary')) + ', 1)';
                background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='" + $primaryColorRgba + "' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
        }
    }
    .navbar-brand {
        color: map-get($theme-colors, 'primary');
        font-size: 2em;
        font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;

        &:hover, &:focus,
        &:active, &.active {
            color: darken(map-get($theme-colors, 'primary'), 10%);
        }
    }

    .navbar-nav {
        .nav-link {
            font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: 1px;
            color: #ffffff;
            @include user-select(none);


            -webkit-transition: color 300ms, background-color 300ms;
            -moz-transition: color 300ms, background-color 300ms;
            transition: color 300ms, background-color 300ms;

            &:hover, &:focus {
                color: map-get($theme-colors, 'primary');
                outline: none;
            }
        }

        .nav-item.active .nav-link {
            color: #222222;
            background-color: map-get($theme-colors, 'primary');
            border-radius: 3px;

            &:hover,
            &:focus {
                background-color: darken(map-get($theme-colors, 'primary'), 10%);
            }
        }
    }
}

@include media-breakpoint-down(md) {
    nav.navbar {
        .navbar-brand {
            font-size: 1.5em;
        }
        .navbar-nav {
            .nav-link {
                padding: 5px 7px;
                &.active {
                    border-radius: 0;
                }
            }
        }
    }
}
@media (max-width: 360px) {
    nav.navbar {
        .navbar-brand {
            font-size: 1em;
        }
    }
}

/* Highlight Color Customization */
::-moz-selection {
    text-shadow: none;
    color: $custom-dark1;
    background: map-get($theme-colors, 'primary');
}
::selection {
    text-shadow: none;
    color: $custom-dark1;
    background: map-get($theme-colors, 'primary');
}
img::-moz-selection {
    background: transparent;
}
img::selection {
    background: transparent;
}
body {
    -webkit-tap-highlight-color: map-get($theme-colors, 'primary');
}

/* Page */
.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

nav, section, footer {
    margin: 0 auto;
    max-width: 1300px;
}

section, footer {
    background-color: rgba(238, 238, 238, 0.93);
    color: #222222;
}

/* Global Section Styles */
section {
    padding: 100px 0;
    .section-heading {
        margin-bottom: 75px;

        h2 {
            font-size: 28px;
            margin-top: 0;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 16px;
            @include serif-font;
            text-transform: none;
            font-style: italic;
            font-weight: 400;
            color: #777777;
        }
    }
}

@include media-breakpoint-up(sm) {
    section {
        .section-heading {
            h2 {
                font-size: 40px;
            }
        }
    }
}

@media(min-width:768px) {
    section {
        padding: 150px 0;
    }
}

footer {
    padding: 1rem;
    /*
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    color: #FFFFFF;
    background-color: #efefef;
    text-align: center;
    z-index: 9999;
    */
}
/* Footer navigation */
footer {
    //padding: 25px 0;

    .nav-link {
        color: #222222;
        &:hover, &:focus, &:active {
            color: #000000;
            text-decoration: underline;
        }
    }

    span.copyright {
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }
    ul {
        margin-bottom: 0;
        line-height: 40px;
        @include heading-font;
        text-transform: none;
    }
}

/* Background */
.post-headerheader, .post-header-blur {
    /* background generated by generate.php */
    background-size: cover;
}

.bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.post-header-blur {
    z-index: -2;
}

.post-header {
    z-index: -1;
}

.post-header-enhanced {
    /* background generated by generate.php */
    background-size: cover;
    animation: sharpen 0.75s both;
}

@keyframes sharpen {
    from {
        filter: blur(20px);
    }
    to {
        filter: blur(0px);
    }
}

/* Welcome */
#welcome {
    padding-top: 60px;
}
.logo-guesthouse {
    margin-bottom: 60px;

    img {
        width: 80%;
    }
}
@include media-breakpoint-up(sm) {
    .logo-guesthouse {
        img {
            width: 70%;
        }
    }
}
@include media-breakpoint-up(md) {
    #welcome {
        padding-top: 100px;
    }
    .logo-guesthouse {
        img {
            width: 60%;
        }
    }
}
@include media-breakpoint-up(xl) {
    .logo-guesthouse {
        img {
            width: 50%;
        }
    }
}

/* Service */
.service-item {
    .fa-circle {
        color: map-get($theme-colors, 'primary');
    }
    h4 {
        margin: 15px 0;
        text-transform: none;
    }
    p {
        color: #777777;
    }
}

/* Apartments */
.apartments {
    .apartment-image {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
    }
}

/* Rooms */
.rooms {
    background-color: rgba(238, 238, 238, 0.93);
}
.floor {
    .heading {
        .subheading {
            display: block;
            color: #6c757d;
        }
    }

    .floorplan {
        margin-bottom: 15px;
    }
}
.room-box {
    margin-bottom: 20px;
    padding: 9px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    > * {
        flex: 1 1 auto;
    }
    >:first-child {
        flex: 0 0 auto;
    }
    >:last-child {
        flex: 0 0 auto;
        margin-bottom: 0 !important;
        padding-bottom: 0;
    }

    .heading {
        margin-top: 0;

        .subheading {
            display: block;
            color: #6c757d;
        }
    }

    ul li {
        padding-right: 20px;
        text-align: left;
    }

    .carousel {
        width: 300px;
        height: 300px;
        margin: auto;
    }
}

@include media-breakpoint-up(xl) {
    .room-grid > div {
        position: relative;

        .room-box {
            height: 100%;
        }

        .carousel {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
        }
    }
}

/* Contact Section */
#contact {
    color: white;
    background-color: #222222;
    background-image: url('../../public/images/gasthaus.png');
    background-repeat: no-repeat;
    background-size: 125%;
    background-position: 60% 55%;
}

@media(max-width: 511px) {
    #contact .section-heading h2 {
        font-size: 30px;
    }
}

/* Responsive */
@media(max-width: 991px) {
    h2 {
        text-align: center;
    }
}

/* Deprecated */
/*
@todo Better global?!
.form-control:focus {
    border-color: $theme-primary;
    box-shadow: none;
}
::-webkit-input-placeholder {
    color: #000000;
}
:-moz-placeholder {
    color: #000000;
}
::-moz-placeholder {
    color: #000000;
}
:-ms-input-placeholder {
    color: #000000;
}
*/

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    nav {
        .scrollable-menu {
            height: auto;
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
        }
    }
}

.featured {
    margin-bottom: 20px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px;
        padding: 0;
        width: 1.9em;
        height: 1.9em;
        background-color: #404040;
        color: #ffffff;
        font-size: 3em;
        border-radius: 12px;
    }
    .content {
        h5 {
            padding-bottom: 5px;
            border-bottom: 1px solid lightgrey;
        }
        ul {
            text-align: left;
        }
    }
}

.gallery {
    img {
        max-width: 100%
    }
}

.facilitiesChecklist {
    h5 {
        margin: 1em 0 0;
        color: #333;
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase;
    }
    h5 :first-child {
        min-width: 19px;
        text-align: center;
    }
    h5 + p {
        color: #6b6b6b;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 2px 0 5px 28px;
    }
    h5 + p.policy {
        color: #333;
    }

    ul {
        margin: 8px 0;
        padding: 0 0 0 25px;
        list-style: none;
    }
    ul li {
        padding: 2px 0;
        font-size: 14px;
    }
}
